<script>
import Filtro01 from './filtros/Filtro01.vue'
import Filtro02 from './filtros/Filtro02.vue'
import Filtro05 from './filtros/Filtro05.vue'
import Filtro06 from './filtros/Filtro06.vue'
import Relatorio from '@/components/shared/relatorio/Relatorio.vue'
import ListaRelatorios from '@/domain/ListaRelatorios.js'

export default {
  name: 'LayoutRelatorioGestao',

  components: {
    Filtro01,
    Filtro02,
    Filtro05,
    Filtro06,
  },

  extends: Relatorio,

  data() {
    return {
      relatorioExclusivoModulo: [],
    }
  },

  mounted() {
    this.adicionarRelatorio()
    this.carregarTipos()
  },

  methods: {
    adicionarRelatorio() {
      this.listaRelatorios = new ListaRelatorios()
      // this.relatorioExclusivoModulo =
      //   this.listaRelatorios.relatoriosPorTipo.get('Movimento')
      // this.relatorioExclusivoModulo.push({
      //   nome: 'Taxa Assistencial',
      //   nomeRelatorio: 'TaxaAssistencial',
      //   componente: 'Filtro01',
      //   tipoGeradorDeRelatorio: 'html',
      //   permissoes: ['GESTÃO', 'GESTÃO-ADMIN'],
      // })
      // this.listaRelatorios.adicionarRelatorio(
      //   'Financeiro',
      //   this.relatorioExclusivoModulo,
      // )
      this.listaRelatorios.adicionarRelatorio('Financeiro', [
        {
          nome: 'Taxa Assistencial',
          nomeRelatorio: 'TaxaAssistencial',
          componente: 'FiltroTaxaAssistencial',
          tipoGeradorDeRelatorio: 'html',
          permissoes: ['GESTÃO', 'GESTÃO-ADMIN', 'GESTÃO-FINANCEIRA'],
        },
        {
          nome: 'Relatório de Cobrança',
          nomeRelatorio: 'RelatorioCobranca',
          componente: 'FiltroRelatorioCobranca',
          tipoGeradorDeRelatorio: 'html',
          permissoes: ['GESTÃO', 'GESTÃO-ADMIN', 'GESTÃO-FINANCEIRA'],
        },
      ])
    },

    carregarTipos() {
      this.listaRelatorios.relatoriosPorTipo.forEach((value, key) => {
        this.tipos.push(key)
      })

      this.relatorio.tipo = this.listaRelatorios.relatoriosPorTipo
        .keys()
        .next().value

      this.carregarNomesRelatoriosPorTipoSelecionado()
    },

    carregarNomesRelatoriosPorTipoSelecionado() {
      this.relatorios = this.listaRelatorios.relatoriosPorTipo.get(
        this.relatorio.tipo,
      )

      //Verificando permissões de visualização do relatório
      this.relatorios = this.relatorios
        .map((r) => {
          if (
            r.permissoes.some((permissao) =>
              this.$auth.roles.includes(permissao),
            )
          ) {
            return r // mantém o objeto atual no novo array
          }
          return null // descarta o objeto que não atende à condição
        })
        .filter((r) => r !== null)

      this.componente = ''
    },
  },
}
</script>
