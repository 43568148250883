<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro01 from '@/components/shared/relatorio/filtros/Filtro01.vue'

export default {
  extends: Filtro01,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarListaConsignatarias() {
      if (this.$auth.hasRoleGestao()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res
          if (this.store.consignatariaSelecionada !== null) {
            this.consignatariasSelecionadas = res.filter((item) => {
              return item.id === this.store.consignatariaSelecionada.id
            })
          }
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res
            if (this.store.consignatariaSelecionada !== null) {
              this.consignatariasSelecionadas = res.filter((item) => {
                return item.id === this.store.consignatariaSelecionada.id
              })
            }
          })
      }
    },
  },
}
</script>
